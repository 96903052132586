.login-form-button {
    width: 100%;
}

.leftAlign {
    text-align: left;
    float: left;
}

.outer {
    display: table;
    position: absolute;
    height: 90%;
    width: 100%;
    /* padding: 10%;*/
}

.middle {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.inner {
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 400px;
    padding: 20px 20px 5px 20px;
    background-color: #ffffff;
    border-radius: 10px;
}

.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
}

.strike > span {
    position: relative;
    display: inline-block;
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: black;
}

.strike > span:before {
    right: 100%;
    margin-right: 15px;
}

.strike > span:after {
    left: 100%;
    margin-left: 15px;
}
